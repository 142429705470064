import React from 'react';

import { Container, H1, Seo } from '../../components';

const TermsOfServices: React.FC = React.memo(() => (
  <>
    <Seo title="Dog Training Book | Raising Dog" />
    <Container>
      <H1 margin="3.5rem 0" textAlign="center">
        Terms Of Services
      </H1>
    </Container>
  </>
));

TermsOfServices.displayName = 'Terms of services';

export default TermsOfServices;
